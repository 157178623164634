import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "src/app/core/auth.service";
import { Role } from "src/app/models/Role";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  public toggleNavbar = false;

  Role = Role;

  constructor(public authService: AuthService) {}

  ngOnInit(): void {}
}
