import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { Product } from "src/app/models/Product";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-dashboard-card",
  templateUrl: "./dashboard-card.component.html",
  styleUrls: ["./dashboard-card.component.css"],
})
export class DashboardCardComponent implements OnInit {
  @Input() product: Product;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {}

  openProductUrl(): void {
    window.open(this.product.url + `?company=${encodeURIComponent(this.auth.loggedInUser.company.authServerName)}`, "_blank").focus();
  }
}
