import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";

@Component({
  selector: "app-manage-my-company-page",
  templateUrl: "./manage-my-company-page.component.html",
  styleUrls: ["./manage-my-company-page.component.css"],
})
export class ManageMyCompanyPageComponent implements OnInit {
  constructor(public authService: AuthService) {}

  ngOnInit(): void {}
}
