<div class="d-flex my-2">
  <button class="btn btn-primary" (click)="setUserForAdding(); modal.open(addOrEditUserContent)" i18n>Add user</button>
</div>

<ng-container *ngIf="isLoading">
  <app-spinner></app-spinner>
</ng-container>
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="!isError">
    <ng-container *ngIf="users.length === 0">
      <p i18n>No users yet.</p>
    </ng-container>
    <ng-container *ngIf="users.length > 0">
      <table class="table table-bordered mt-2">
        <thead>
          <tr>
            <th scope="col" i18n>Email</th>
            <th scope="col" i18n>First name</th>
            <th scope="col" i18n>Last name</th>
            <th scope="col" i18n>Roles</th>
            <th scope="col" i18n>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td>{{ user.email }}</td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
            <td>
              <ng-container *ngFor="let role of user.roles"> {{ msg.translateRole(role) }} <br /> </ng-container>
            </td>
            <td>
              <button class="btn btn-info mr-2" i18n (click)="setUserForEditing(user); modal.open(addOrEditUserContent)">Edit</button>
              <button class="btn btn-info mr-2" i18n (click)="setUserForEditingPassword(user); modal.open(editPasswordContent)">Edit password</button>
              <button
                class="btn btn-danger"
                i18n
                (click)="setUserForDeleting(user); modal.open(confirmDeleteContent)"
                *ngIf="authService.loggedInUser.id !== user.id"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isError">
    <p class="text-danger" i18n>An error has occured, please refresh the page and try again.</p>
  </ng-container>
</ng-container>

<ng-template #addOrEditUserContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" *ngIf="userToEdit == null" i18n>Add user to company</h4>
    <h4 class="modal-title" *ngIf="userToEdit" i18n>Edit user: {{ userToEdit.email }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeAddOrEditUserModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group mb-2" *ngIf="userToEdit == null">
      <label><ng-container i18n>Email</ng-container></label>
      <input
        type="email"
        required
        class="form-control"
        i18n-placeholder
        placeholder="Email"
        [(ngModel)]="addOrEditUserData.email"
        [disabled]="isAddingOrEditingLoading"
        (keydown.enter)="onAddOrEditUser()"
      />
    </div>

    <div *ngIf="emailError">
      <small class="text-danger">Invalid email entered!</small>
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>First name</ng-container></label>
      <input
        class="form-control"
        required
        i18n-placeholder
        placeholder="First name"
        [(ngModel)]="addOrEditUserData.firstName"
        [disabled]="isAddingOrEditingLoading"
        (keydown.enter)="onAddOrEditUser()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Last name</ng-container></label>
      <input
        class="form-control"
        required
        i18n-placeholder
        placeholder="Last name"
        [(ngModel)]="addOrEditUserData.lastName"
        [disabled]="isAddingOrEditingLoading"
        (keydown.enter)="onAddOrEditUser()"
      />
    </div>

    <ng-container *ngIf="userToEdit == null">
      <div class="form-group mb-2">
        <label><ng-container i18n>Password</ng-container></label>
        <input
          type="password"
          class="form-control"
          required
          i18n-placeholder
          placeholder="Password"
          [(ngModel)]="addOrEditUserData.password"
          [disabled]="isAddingOrEditingLoading"
          (keydown.enter)="onAddOrEditUser()"
        />
      </div>

      <div class="form-group mb-2">
        <label><ng-container i18n>Password confirm</ng-container></label>
        <input
          type="password"
          class="form-control"
          required
          i18n-placeholder
          placeholder="Password confirm"
          [(ngModel)]="addOrEditUserData.passwordConfirm"
          [disabled]="isAddingOrEditingLoading"
          (keydown.enter)="onAddOrEditUser()"
        />
      </div>

      <div *ngIf="passwordNotMatchError">
        <small class="text-danger">Passwords don't match!</small>
      </div>
    </ng-container>

    <div class="form-group mb-2">
      <label><ng-container i18n>Roles</ng-container></label>
      <app-multi-checkbox-input [possibleValues]="allRoles" [(selectedValues)]="addOrEditUserData.roles"></app-multi-checkbox-input>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="closeAddOrEditUserModal()" [disabled]="isAddingOrEditingLoading">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button class="btn btn-primary" [disabled]="isAddingOrEditingLoading || !canAddOrEditUser()" (click)="onAddOrEditUser()">
      <ng-container i18n *ngIf="userToEdit == null">Add</ng-container>
      <ng-container i18n *ngIf="userToEdit != null">Edit</ng-container>
    </button>
  </div>
</ng-template>

<ng-template #editPasswordContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n>Edit user password: {{ userToEdit.email }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeEditPasswordModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group mb-2">
      <label><ng-container i18n>Password</ng-container></label>
      <input
        type="password"
        class="form-control"
        required
        i18n-placeholder
        placeholder="Password"
        [(ngModel)]="addOrEditUserData.password"
        [disabled]="isAddingOrEditingLoading"
        (keydown.enter)="onEditUserPassword()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Password confirm</ng-container></label>
      <input
        type="password"
        class="form-control"
        required
        i18n-placeholder
        placeholder="Password confirm"
        [(ngModel)]="addOrEditUserData.passwordConfirm"
        [disabled]="isAddingOrEditingLoading"
        (keydown.enter)="onEditUserPassword()"
      />
    </div>

    <div *ngIf="passwordNotMatchError">
      <small class="text-danger">Passwords don't match!</small>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="closeEditPasswordModal()" [disabled]="isAddingOrEditingLoading">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button class="btn btn-primary" i18n [disabled]="isAddingOrEditingLoading || !canEditPassword()" (click)="onEditUserPassword()">Edit</button>
  </div>
</ng-template>

<ng-template #confirmDeleteContent let-modal>
  <app-confirmation-modal
    *ngIf="userToDelete != null"
    [isLoading]="loadingDelete"
    header="Delete user?"
    i18n-header
    subHeader="Confirm deleting user {{ userToDelete.email }}"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Delete"
    i18n-confirmButtonText
    confirmButtonClass="btn-danger"
    (onCancel)="dismissAllModals()"
    (onConfirm)="deleteUser()"
  >
  </app-confirmation-modal>
</ng-template>
