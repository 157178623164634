import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NullValidationHandler, OAuthService } from "angular-oauth2-oidc";
import { getAuthConfig } from "../core/auth-config";
import { AuthService } from "../core/auth.service";
import { LocalStorageItems, LocalStorageService } from "../local-storage.service";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-company-login",
  templateUrl: "./company-login.component.html",
  styleUrls: ["./company-login.component.css"],
})
export class CompanyLoginComponent implements OnInit {
  companyLoginForm = new FormGroup({
    companyName: new FormControl("", [Validators.required]),
  });

  constructor(public authService: AuthService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.company) {
        this.companyLoginForm.setValue({ companyName: params.company });
      }
    });
  }

  async selectCompanyName(): Promise<void> {
    const companyName = this.companyLoginForm.value.companyName;
    const companyExists = await this.checkCompanyExists(companyName);

    if (!companyExists) {
      this.companyLoginForm.controls.companyName.setErrors({ companyNotExistError: true });
      return;
    }

    this.companyLoginForm.controls.companyName.setErrors(null);

    LocalStorageService.setItem(LocalStorageItems.SSO_REALM, companyName);
    LocalStorageService.setItem(LocalStorageItems.LAST_LOGIN_AT, new Date().getTime());
    await this.authService.runInitialLoginSequence(true);
    this.authService.login();
  }

  async checkCompanyExists(company: string): Promise<boolean> {
    if (!company || company.trim().length === 0) {
      return false;
    }

    try {
      // const data = await this.restService.companyExists(company);
      return true;
    } catch (e) {
      return false;
    }
  }
}
