<div class="login-container">
  <img class="login-logo" src="/assets/logo.png" alt="Omniopti" />
  <h3 class="login-title mb-3" i18n>Omniopti account login</h3>

  <form (ngSubmit)="selectCompanyName()" [formGroup]="companyLoginForm" class="login-form">
    <div class="form-group mt-3">
      <label for="companyName" i18n>
        Enter your company name to login. Contact <a href="mailto:info@omniopti.eu">info@omniopti.eu</a> for more information.
      </label>
      <input aria-describedby="emailHelp" class="form-control" formControlName="companyName" id="companyName" placeholder="Company name" />
      <div>
        <small *ngIf="authService.selectedCompanyDoesntExist === true" class="text-danger"> This company does not exist. </small>
      </div>
    </div>

    <button [disabled]="!companyLoginForm.valid" class="btn btn-primary w-100" type="submit">Continue</button>
  </form>
</div>
