import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { Role } from "../models/Role";

@Injectable({
  providedIn: "root",
})
export class MessagesService {
  translations;

  constructor(@Inject(LOCALE_ID) public locale: string) {
    if (locale === "sl") {
      this.translations = this.translationsSl;
    } else {
      this.translations = this.translationsEn;
    }
  }

  private rolesEn = {
    [Role.COMPANY_ADMIN]: "Company admin",
    [Role.OWL_WAREHOUSE]: "Warehouse (OWL)",
    [Role.YAMAS_GATEMANAGER]: "Gate manager (YAMAS)",
    [Role.YAMAS_WAREHOUSEMANAGER]: "Warehouse manager (YAMAS)",
  };

  private rolesSl = {
    [Role.COMPANY_ADMIN]: "Company admin",
    [Role.OWL_WAREHOUSE]: "Warehouse (OWL)",
    [Role.YAMAS_GATEMANAGER]: "Gate manager (YAMAS)",
    [Role.YAMAS_WAREHOUSEMANAGER]: "Warehouse manager (YAMAS)",
  };

  private translationsEn = {
    ERROR_ADDING_COMPANY: "Could not add company, please check if the name of the company is unique!",
    UNKNOWN_ERROR: "Unknown error has occured!",
    ROLES: this.rolesEn,
    SUCCESS: "Success!",
    UPDATE_SUCCESS: "Update successful!",
    PASSWORDS_DONT_MATCH: "Passwords don't match!",
    PASSWORD_TOO_WEAK:
      "Your password is too weak. It has to be at least 10 characters long and must contain 3 of the following sets: upper case, lower case, numbers and special characters.",
  };

  private translationsSl = {
    ERROR_ADDING_COMPANY: "Napaka pri dodajanju podjetja, prosimo preverite ali je ime podjetja unikatno!",
    UNKNOWN_ERROR: "Zgodila se je neznana napaka!",
    ROLES: this.rolesSl,
    SUCCESS: "Uspeh!",
    UPDATE_SUCCESS: "Posodobitev uspešna!",
    PASSWORDS_DONT_MATCH: "Gesli se ne ujemata!",
    PASSWORD_TOO_WEAK:
      "Geslo ni dovolj močno. Dolgo mora biti vsaj 10 znakov in vsebovati 3 izmed naslednjih skupin: velike črke, male črke, številke in posebni znaki.",
  };

  errorAddingCompany(): string {
    return this.translations.ERROR_ADDING_COMPANY;
  }

  unknownError(): string {
    return this.translations.UNKNOWN_ERROR;
  }

  translateRole(role: Role): string | null {
    return this.translations.ROLES[role];
  }

  success(): string {
    return this.translations.SUCCESS;
  }

  public passwordTooWeak() {
    return this.translations.PASSWORD_TOO_WEAK;
  }

  updateSuccess(): string {
    return this.translations.UPDATE_SUCCESS;
  }

  public passwordDontMatch() {
    return this.translations.PASSWORDS_DONT_MATCH;
  }
}
