import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Company } from "src/app/models/Company";
import { RestService } from "src/app/services/rest.service";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { MessagesService } from "src/app/services/messages.service";
import { Role } from "src/app/models/Role";
import { isThisTypeNode } from "typescript";

@Component({
  selector: "app-company-view",
  templateUrl: "./company-view.component.html",
  styleUrls: ["./company-view.component.css"],
})
export class CompanyViewComponent implements OnInit, OnChanges {
  isLoading = true;
  isError = false;
  company: Company | null = null;

  editProductsData: Pick<Company, "canAccessOwl" | "canAccessYAMAS">;

  faCheck = faCheck;
  faTimes = faTimes;

  isEditingProductsLoading = false;

  companyId: number | null = null;

  @Input() isCompanyAdmin: boolean = false;

  Role = Role;

  updateCompanyData = {
    name: "",
    phone: "",
    contactPerson: "",
    address: "",
  };
  isUpdatingCompany = false;

  constructor(
    private rest: RestService,
    private route: ActivatedRoute,
    public modal: NgbModal,
    private toast: ToastrService,
    private msg: MessagesService,
    private router: Router
  ) {}

  public setEditProductsData() {
    if (!this.company) {
      return;
    }

    this.editProductsData = {
      canAccessOwl: this.company.canAccessOwl,
      canAccessYAMAS: this.company.canAccessYAMAS,
    };
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.id != null) {
        this.companyId = Number(params.id);
        this.fetchCompany();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isCompanyAdmin) {
      if (this.isCompanyAdmin) {
        this.fetchCompany();
      }
    }
  }

  private getCompanyIdUrlPart(): string {
    return this.companyId != null ? `/${this.companyId}` : "";
  }

  async fetchCompany() {
    this.isLoading = true;
    this.isError = false;

    try {
      this.company = await this.rest.getRequest<Company>("api/companyAdmin/getCompany" + this.getCompanyIdUrlPart());
      this.updateCompanyData = {
        name: this.company.name,
        address: this.company.address,
        phone: this.company.phone,
        contactPerson: this.company.contactPerson,
      };
    } catch (e) {
      this.isError = true;
      console.error("Error", e);
    }

    this.isLoading = false;
  }

  async onEditProducts() {
    if (!this.editProductsData) {
      return;
    }

    this.isEditingProductsLoading = true;

    try {
      this.company = await this.rest.postRequest("api/superAdmin/updateCompanyProducts" + this.getCompanyIdUrlPart(), {
        canAccessOwl: this.editProductsData.canAccessOwl,
        canAccessYAMAS: this.editProductsData.canAccessYAMAS,
      });
      this.fetchCompany();
      this.modal.dismissAll();
    } catch (e) {
      console.error("Error", e);
      this.toast.error(this.msg.unknownError());
    }

    this.isEditingProductsLoading = false;
  }

  async deleteCompany() {
    try {
      await this.rest.deleteRequest("api/superAdmin/deleteCompany" + this.getCompanyIdUrlPart(), {});
      this.modal.dismissAll();
      this.router.navigate(["/company-management"]);
    } catch (e) {
      console.error("Error", e);
      this.toast.error(this.msg.unknownError());
    }
  }

  async UpdateCompany() {
    try {
      this.isUpdatingCompany = true;
      const { name, ...updateData } = this.updateCompanyData;
      await this.rest.postRequest("api/companyAdmin/updateCompanyData" + this.getCompanyIdUrlPart(), updateData);
      this.toast.success(this.msg.success());
    } catch (e) {
      console.error("Error", e);
      this.toast.error(this.msg.unknownError());
    }

    this.isUpdatingCompany = false;
  }

  public dismissAllModals() {
    this.modal.dismissAll();
  }
}
