import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

enum Method {
  GET,
  POST,
  PATCH,
  PUT,
  DELETE,
}

@Injectable({
  providedIn: "root",
})
export class RestService {
  constructor(private http: HttpClient, private oAuth: OAuthService) {}

  private defaultHeaders: HttpHeaders = new HttpHeaders({
    "content-type": "application/json; charset=utf-8",
  });

  public getHeaders(): HttpHeaders {
    const headers = this.defaultHeaders;
    const accessToken = this.oAuth.getAccessToken();
    if (accessToken) {
      return headers.append("Authorization", "Bearer " + accessToken);
    }

    return headers;
  }

  public getRequest<T>(path: string): Promise<T> {
    return this.makeHttpRequest<T>(Method.GET, path, this.getHeaders());
  }

  public postRequest<T>(path: string, body: any): Promise<T> {
    return this.makeHttpRequest<T>(Method.POST, path, this.getHeaders(), body);
  }

  public deleteRequest<T>(path: string, body: any): Promise<T> {
    return this.makeHttpRequest<T>(Method.DELETE, path, this.getHeaders(), body);
  }

  private makeHttpRequest<T>(method: Method, path: string, headers: HttpHeaders, body: any = null): Promise<T> {
    return this.makeHttpRequestObservable<T>(method, path, headers, body).toPromise();
  }

  private makeHttpRequestObservable<T>(method: Method, path: string, headers: HttpHeaders, body: any = null): Observable<T> {
    const pathPrefix = environment.apiUrl
    path = `${pathPrefix}/${path}`
    if (method === Method.GET) {
      return this.http.get<T>(path, { headers });
    } else if (method === Method.POST) {
      return this.http.post<T>(path, body, { headers });
    } else if (method === Method.PATCH) {
      return this.http.patch<T>(path, body, { headers });
    } else if (method === Method.DELETE) {
      return this.http.delete<T>(path, { headers });
    } else if (method === Method.PUT) {
      return this.http.put<T>(path, body, { headers });
    }

    return of({} as T);
  }
}
