<div class="container app-container">
  <ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-container *ngIf="!isLoading && company">
    <h1 class="app-heading">
      <ng-container i18n>Company: {{ company.name }}</ng-container>
    </h1>
    <div class="mb-2"></div>

    <button class="btn btn-danger mb-3" *ngxPermissionsOnly="Role.SUPER_ADMIN" (click)="modal.open(confirmDeleteContent)">Delete company</button>

    <h5 i18n>Basic data</h5>
    <hr />

    <div class="form-group">
      <label for="name"><ng-container i18n>Name</ng-container></label>
      <input type="text" class="form-control" id="name" disabled [(ngModel)]="updateCompanyData.name" />
    </div>

    <div class="form-group">
      <label for="phone"><ng-container i18n>Phone</ng-container></label>
      <input type="text" class="form-control" id="phone" [disabled]="isUpdatingCompany" [(ngModel)]="updateCompanyData.phone" />
    </div>

    <div class="form-group">
      <label for="address"><ng-container i18n>Address</ng-container></label>
      <input type="text" class="form-control" id="address" [disabled]="isUpdatingCompany" [(ngModel)]="updateCompanyData.address" />
    </div>

    <div class="form-group">
      <label for="contactPerson"><ng-container i18n>Contact person</ng-container></label>
      <input type="text" class="form-control" id="contactPerson" [disabled]="isUpdatingCompany" [(ngModel)]="updateCompanyData.contactPerson" />
    </div>

    <button class="btn btn-primary mt-2 mb-4" i18n (click)="UpdateCompany()" [disabled]="isUpdatingCompany">Update data</button>

    <h5 i18n>Products</h5>
    <hr />

    <div>
      <span class="mr-1" i18n>Can access YAMAS:</span>
      <fa-icon *ngIf="company.canAccessYAMAS" style="color: green" [icon]="faCheck"></fa-icon>
      <fa-icon *ngIf="!company.canAccessYAMAS" style="color: red" [icon]="faTimes"></fa-icon>
    </div>

    <div>
      <span class="mr-1" i18n>Can access OWL:</span>
      <fa-icon *ngIf="company.canAccessOwl" style="color: green" [icon]="faCheck"></fa-icon>
      <fa-icon *ngIf="!company.canAccessOwl" style="color: red" [icon]="faTimes"></fa-icon>
    </div>

    <button *ngxPermissionsOnly="Role.SUPER_ADMIN" class="btn btn-primary mt-2" i18n (click)="setEditProductsData(); modal.open(editCompanyProductsContent)">
      Manage products
    </button>

    <h5 i18n class="mt-3">Users</h5>
    <hr />
    <app-company-users [companyId]="companyId"></app-company-users>
  </ng-container>
  <ng-container *ngIf="!isLoading && (!company || isError)">
    <p class="text-danger" i18n>An error has occured, please refresh the page and try again.</p>
  </ng-container>
</div>

<ng-template #editCompanyProductsContent let-modal>
  <ng-container *ngIf="company && editProductsData">
    <div class="modal-header">
      <h4 class="modal-title" i18n>Edit company products: {{ company.name }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <app-checkbox [(value)]="editProductsData.canAccessYAMAS" name="canAccessYAMAS">
        <ng-container i18n>Enable YAMAS for company</ng-container>
      </app-checkbox>

      <app-checkbox [(value)]="editProductsData.canAccessOwl" name="canAccessOWL">
        <ng-container i18n>Enable OWL for company</ng-container>
      </app-checkbox>
    </div>

    <div class="modal-footer d-flex justify-content-between">
      <button type="submit" class="btn" (click)="modal.close()" [disabled]="isEditingProductsLoading">
        <ng-container i18n="Cancel">Cancel</ng-container>
      </button>
      <button class="btn btn-primary" i18n [disabled]="isEditingProductsLoading" (click)="onEditProducts()">Edit</button>
    </div></ng-container
  >
</ng-template>

<ng-template #confirmDeleteContent let-modal>
  <app-confirmation-modal
    [isLoading]="loadingDelete"
    header="Delete company?"
    i18n-header
    subHeader="Confirm deleting company"
    i18n-subHeader
    cancelButtonText="Cancel"
    i18n-cancelButtonText
    confirmButtonText="Delete"
    i18n-confirmButtonText
    confirmButtonClass="btn-danger"
    (onCancel)="dismissAllModals()"
    (onConfirm)="deleteCompany()"
  >
  </app-confirmation-modal>
</ng-template>
