import { Injectable } from "@angular/core";

export enum LocalStorageItems {
  SSO_REALM = "sso-realm",
  LAST_LOGIN_AT = "last-login-at",
}

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  constructor() {}

  static getItem(key: LocalStorageItems): any {
    const val = localStorage.getItem(key);
    if (!val) {
      return null;
    }

    return JSON.parse(val);
  }

  static setItem(key: LocalStorageItems, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static deleteItem(key: LocalStorageItems): void {
    localStorage.removeItem(key);
  }
}
