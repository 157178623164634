import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth.service";
import { Company } from "src/app/models/Company";
import { Role } from "src/app/models/Role";
import { User } from "src/app/models/User";
import { MessagesService } from "src/app/services/messages.service";
import { RestService } from "src/app/services/rest.service";
import { SupportService } from "src/app/services/support.service";
import { MultiCheckboxInputOption } from "src/app/shared/multi-checkbox-input/multi-checkbox-input.component";

@Component({
  selector: "app-company-users",
  templateUrl: "./company-users.component.html",
  styleUrls: ["./company-users.component.css"],
})
export class CompanyUsersComponent implements OnInit {
  isLoading = true;
  isAddingOrEditingLoading = false;
  users: User[] = [];

  isError = false;

  addOrEditUserData = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    roles: [],
  };

  editPasswordData = {
    password: "",
    passwordConfirm: "",
  };

  emailError = false;
  passwordNotMatchError = false;

  @Input() companyId: number | null = null;

  userToDelete: User | null = null;
  userToEdit: User | null = null;
  loadingDelete = false;

  allRoles: MultiCheckboxInputOption[] = [
    { value: Role.COMPANY_ADMIN, name: this.msg.translateRole(Role.COMPANY_ADMIN) },
    { value: Role.OWL_WAREHOUSE, name: this.msg.translateRole(Role.OWL_WAREHOUSE) },
    { value: Role.YAMAS_GATEMANAGER, name: this.msg.translateRole(Role.YAMAS_GATEMANAGER) },
    { value: Role.YAMAS_WAREHOUSEMANAGER, name: this.msg.translateRole(Role.YAMAS_WAREHOUSEMANAGER) },
  ];

  constructor(
    private rest: RestService,
    public modal: NgbModal,
    private toast: ToastrService,
    public msg: MessagesService,
    private support: SupportService,
    public authService: AuthService
  ) {
    this.resetAddOrEditUserData();
  }

  ngOnInit(): void {
    this.fetchUsers();
  }

  resetAddOrEditUserData() {
    this.addOrEditUserData = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      roles: [],
    };

    this.emailError = false;
    this.passwordNotMatchError = false;
    this.isAddingOrEditingLoading = false;
    this.loadingDelete = false;
    this.userToDelete = null;
    this.userToEdit = null;
  }

  setUserForAdding() {
    this.resetAddOrEditUserData();
  }

  setUserForEditing(user: User) {
    this.resetAddOrEditUserData();

    this.addOrEditUserData = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles,
      password: "",
      passwordConfirm: "",
    };

    this.userToEdit = user;
  }

  setUserForEditingPassword(user: User) {
    this.setUserForEditing(user);
  }

  setUserForDeleting(user: User) {
    this.resetAddOrEditUserData();
    this.userToDelete = user;
  }

  private getCompanyIdUrlPart(): string {
    return this.companyId != null ? `/${this.companyId}` : "";
  }

  async fetchUsers() {
    this.isLoading = true;
    this.isError = false;

    try {
      this.users = await this.rest.getRequest<User[]>("api/companyAdmin/listCompanyUsers" + this.getCompanyIdUrlPart());
    } catch (e) {
      this.isError = true;
      console.error("Error", e);
    }

    this.isLoading = false;
  }

  public canAddOrEditUser(): boolean {
    if (this.addOrEditUserData.roles.length === 0) {
      return false;
    }

    const isEditing = this.userToEdit != null;
    let userDataToCheck: any = this.addOrEditUserData;
    let password, passwordConfirm;
    if (isEditing) {
      ({ password, passwordConfirm, ...userDataToCheck } = this.addOrEditUserData);
    }

    return this.checkIfAllArrayPropertiesNotBlank(Object.values(userDataToCheck));
  }

  public async onAddOrEditUser() {
    const isEditing = this.userToEdit != null;
    if (!this.canAddOrEditUser()) {
      return;
    }

    if (!this.support.validateEmail(this.addOrEditUserData.email)) {
      this.emailError = true;
      return;
    }

    this.emailError = false;

    if (!isEditing && !this.support.checkPasswordStrength(this.addOrEditUserData.password)) {
      this.passwordNotMatchError = false;
      return;
    }

    if (!isEditing && this.addOrEditUserData.password !== this.addOrEditUserData.passwordConfirm) {
      this.passwordNotMatchError = true;
      return;
    }

    this.passwordNotMatchError = false;

    this.isAddingOrEditingLoading = true;

    try {
      if (isEditing) {
        await this.editUser(this.userToEdit.id);
      } else {
        await this.addUser();
      }

      this.toast.success(this.msg.success());
      this.fetchUsers();
      this.closeAddOrEditUserModal();
    } catch (e) {
      this.toast.error(this.msg.unknownError());
    }

    this.isAddingOrEditingLoading = false;
  }

  private async addUser() {
    await this.rest.postRequest<Company>("api/companyAdmin/createCompanyUser" + this.getCompanyIdUrlPart(), {
      email: this.addOrEditUserData.email,
      password: this.addOrEditUserData.password,
      firstName: this.addOrEditUserData.firstName,
      lastName: this.addOrEditUserData.lastName,
      roles: this.addOrEditUserData.roles,
    });
  }

  private async editUser(userId: number) {
    await this.rest.postRequest<Company>("api/companyAdmin/updateCompanyUser/" + userId, {
      email: this.addOrEditUserData.email,
      firstName: this.addOrEditUserData.firstName,
      lastName: this.addOrEditUserData.lastName,
      roles: this.addOrEditUserData.roles,
    });
  }

  public canEditPassword() {
    const properties = [this.addOrEditUserData.password, this.addOrEditUserData.passwordConfirm];
    return this.checkIfAllArrayPropertiesNotBlank(properties);
  }

  public async onEditUserPassword() {
    if (!this.canEditPassword()) {
      return;
    }

    if (!this.support.checkPasswordStrength(this.addOrEditUserData.password)) {
      this.passwordNotMatchError = false;
      return;
    }

    if (this.addOrEditUserData.password !== this.addOrEditUserData.passwordConfirm) {
      this.passwordNotMatchError = true;
      return;
    }

    this.passwordNotMatchError = false;

    this.isAddingOrEditingLoading = true;

    try {
      await this.rest.postRequest<Company>("api/companyAdmin/updateCompanyUser/" + this.userToEdit.id, {
        password: this.addOrEditUserData.password,
      });
      this.toast.success(this.msg.success());
      this.closeEditPasswordModal();
    } catch (e) {
      this.toast.error(this.msg.unknownError());
    }

    this.isAddingOrEditingLoading = false;
  }

  async deleteUser() {
    if (!this.userToDelete) {
      return;
    }

    try {
      await this.rest.deleteRequest("api/companyAdmin/deleteCompanyUser/" + this.userToDelete.id, {});

      this.toast.success(this.msg.success());
      this.modal.dismissAll();
      this.fetchUsers();
    } catch (e) {
      console.error("Error", e);
      this.toast.error(this.msg.unknownError());
    }
  }

  public dismissAllModals() {
    this.modal.dismissAll();
  }

  public closeAddOrEditUserModal() {
    this.dismissAllModals();
    this.resetAddOrEditUserData();
    this.userToEdit = null;
  }

  public closeEditPasswordModal() {
    this.closeAddOrEditUserModal();
  }

  public closeDeleteUserModal() {
    this.dismissAllModals();
    this.userToDelete = null;
  }

  private checkIfAllArrayPropertiesNotBlank(arr: string[]): boolean {
    return arr.every((val) => val.toString().trim().length > 0);
  }
}
