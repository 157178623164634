import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { NgxPermissionsService } from "ngx-permissions";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Role } from "../models/Role";

@Injectable()
export class SuperAdminGuard implements CanActivate {
  constructor(private permissionsService: NgxPermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissionsService.permissions$.pipe(
      filter((role) => role[Role.SUPER_ADMIN] != null),
      map((role) => role[Role.SUPER_ADMIN] != null)
    );
  }
}
