import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { Company } from "src/app/models/Company";
import { RestService } from "src/app/services/rest.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { MessagesService } from "src/app/services/messages.service";
import { Role } from "src/app/models/Role";
import { SupportService } from "src/app/services/support.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-company-management-page",
  templateUrl: "./company-management-page.component.html",
  styleUrls: ["./company-management-page.component.css"],
})
export class CompanyManagementPageComponent implements OnInit {
  isAddingLoading = false;

  isLoading = true;
  isError = false;
  companies: Company[] = [];

  addCompanyAdminEmailError = false;
  addCompanyAdminPasswordNotMatchError = false;

  addCompanyData = {
    name: "",
    phone: "",
    address: "",
    contactPerson: "",
    adminEmail: "",
    adminFirstName: "",
    adminLastName: "",
    adminPassword: "",
    adminPasswordConfirm: "",
    canAccessYAMAS: false,
    canAccessOWL: false,
  };

  constructor(
    private rest: RestService,
    private router: Router,
    private support: SupportService,
    public modal: NgbModal,
    private toast: ToastrService,
    private msg: MessagesService
  ) {
    this.resetAddCompanyData();
  }

  resetAddCompanyData() {
    this.addCompanyData = {
      name: "",
      phone: "",
      address: "",
      contactPerson: "",
      adminEmail: "",
      adminFirstName: "",
      adminLastName: "",
      adminPassword: "",
      adminPasswordConfirm: "",
      canAccessYAMAS: false,
      canAccessOWL: false,
    };
  }

  ngOnInit(): void {
    this.fetchCompanies();
  }

  async fetchCompanies() {
    this.isLoading = true;
    this.isError = false;

    try {
      this.companies = await this.rest.getRequest<Company[]>("api/superAdmin/listCompanies");
    } catch (e) {
      this.isError = true;
      console.error("Error", e);
    }

    this.isLoading = false;
  }

  public canAddCompany(): boolean {
    return Object.values(this.addCompanyData).every((val) => val.toString().trim().length > 0);
  }

  public async onAddCompany() {
    if (!this.canAddCompany()) {
      return;
    }
    if (!this.support.validateEmail(this.addCompanyData.adminEmail)) {
      this.addCompanyAdminEmailError = true;
      return;
    }

    this.addCompanyAdminEmailError = false;

    if (!this.support.checkPasswordStrength(this.addCompanyData.adminPassword)) {
      this.addCompanyAdminPasswordNotMatchError = false;
      return;
    }

    if (this.addCompanyData.adminPassword !== this.addCompanyData.adminPasswordConfirm) {
      this.addCompanyAdminPasswordNotMatchError = true;
      return;
    }

    this.addCompanyAdminPasswordNotMatchError = false;

    this.isAddingLoading = true;

    let addedCompany: Company | null = null;
    try {
      addedCompany = await this.rest.postRequest<Company>("api/superAdmin/createCompany", {
        name: this.addCompanyData.name,
        phone: this.addCompanyData.phone,
        address: this.addCompanyData.address,
        contactPerson: this.addCompanyData.contactPerson,
        canAccessOwl: this.addCompanyData.canAccessOWL,
        canAccessYAMAS: this.addCompanyData.canAccessYAMAS,
      });

      await this.rest.postRequest<Company>("api/companyAdmin/createCompanyUser/" + addedCompany.id, {
        email: this.addCompanyData.adminEmail,
        password: this.addCompanyData.adminPassword,
        firstName: this.addCompanyData.adminFirstName,
        lastName: this.addCompanyData.adminLastName,
        roles: [Role.COMPANY_ADMIN],
      });

      this.toast.success(this.msg.success());
      this.resetAddCompanyData();
      this.router.navigate(["/company", addedCompany.id]);
      this.modal.dismissAll();
    } catch (e) {
      this.toast.error(this.msg.errorAddingCompany());
      if (addedCompany) {
        await this.rest.deleteRequest("api/superAdmin/deleteCompany/" + addedCompany.id, {});
      }
    }

    this.isAddingLoading = false;
  }
}
