<div class="container app-container">
  <h1 class="app-heading">
    <ng-container i18n>Company management</ng-container>
  </h1>

  <div class="d-flex justify-content-center my-2">
    <button class="btn btn-primary" (click)="modal.open(addCompanyContent)" i18n>Add company</button>
  </div>

  <ng-container *ngIf="isLoading">
    <app-spinner></app-spinner>
  </ng-container>
  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="!isError">
      <ng-container *ngIf="companies.length === 0">
        <p i18n>No companies yet.</p>
      </ng-container>
      <ng-container *ngIf="companies.length > 0">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" i18n>Name</th>
              <th scope="col" i18n>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let company of companies">
              <td>{{ company.name }}</td>
              <td>
                <a [routerLink]="'/company/' + company.id" i18n>View and edit</a>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isError">
      <p class="text-danger" i18n>An error has occured, please refresh the page and try again.</p>
    </ng-container>
  </ng-container>
</div>

<ng-template #addCompanyContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" i18n>Add company</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="form-group mb-2">
      <label><ng-container i18n>Name</ng-container></label>
      <input
        class="form-control"
        i18n-placeholder
        required
        placeholder="Name"
        [(ngModel)]="addCompanyData.name"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Phone</ng-container></label>
      <input
        class="form-control"
        i18n-placeholder
        required
        placeholder="Phone"
        [(ngModel)]="addCompanyData.phone"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Address</ng-container></label>
      <input
        class="form-control"
        i18n-placeholder
        required
        placeholder="Address"
        [(ngModel)]="addCompanyData.address"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Contact person</ng-container></label>
      <input
        class="form-control"
        i18n-placeholder
        required
        placeholder="Contact person"
        [(ngModel)]="addCompanyData.contactPerson"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <app-checkbox [(value)]="addCompanyData.canAccessYAMAS" name="canAccessYAMAS">
      <ng-container i18n>Enable YAMAS for company</ng-container>
    </app-checkbox>

    <app-checkbox [(value)]="addCompanyData.canAccessOWL" name="canAccessOWL">
      <ng-container i18n>Enable OWL for company</ng-container>
    </app-checkbox>

    <div class="form-group mb-2">
      <label><ng-container i18n>Admin email</ng-container></label>
      <input
        type="email"
        required
        class="form-control"
        i18n-placeholder
        placeholder="Admin email"
        [(ngModel)]="addCompanyData.adminEmail"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div *ngIf="addCompanyAdminEmailError">
      <small class="text-danger">Invalid admin email entered!</small>
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Admin first name</ng-container></label>
      <input
        class="form-control"
        required
        i18n-placeholder
        placeholder="Admin first name"
        [(ngModel)]="addCompanyData.adminFirstName"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Admin last name</ng-container></label>
      <input
        class="form-control"
        required
        i18n-placeholder
        placeholder="Admin last name"
        [(ngModel)]="addCompanyData.adminLastName"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Admin password</ng-container></label>
      <input
        type="password"
        class="form-control"
        required
        i18n-placeholder
        placeholder="Admin password"
        [(ngModel)]="addCompanyData.adminPassword"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div class="form-group mb-2">
      <label><ng-container i18n>Admin password confirm</ng-container></label>
      <input
        type="password"
        class="form-control"
        required
        i18n-placeholder
        placeholder="Admin password confirm"
        [(ngModel)]="addCompanyData.adminPasswordConfirm"
        [disabled]="isAddingLoading"
        (keydown.enter)="onAddCompany()"
      />
    </div>

    <div *ngIf="addCompanyAdminPasswordNotMatchError">
      <small class="text-danger">Passwords don't match!</small>
    </div>
  </div>

  <div class="modal-footer d-flex justify-content-between">
    <button type="submit" class="btn" (click)="modal.close()" [disabled]="isAddingLoading">
      <ng-container i18n="Cancel">Cancel</ng-container>
    </button>
    <button class="btn btn-primary" i18n [disabled]="isAddingLoading || !canAddCompany()" (click)="onAddCompany()">Add</button>
  </div>
</ng-template>
