import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { filter, switchMap, tap, map } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isDoneLoading$.pipe(
      filter((isDone) => isDone),
      switchMap((_) => this.authService.isAuthenticated$),
      map((isAuthenticated) => !isAuthenticated),
      tap((isNotAuthenticated) => {
        if (!isNotAuthenticated) {
          this.authService.navigateToHome();
        }
      })
    );
  }
}
