import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface MultiCheckboxInputOption {
  value: string;
  name: string;
}

@Component({
  selector: "app-multi-checkbox-input",
  templateUrl: "./multi-checkbox-input.component.html",
  styleUrls: ["./multi-checkbox-input.component.css"],
})
export class MultiCheckboxInputComponent implements OnInit {
  @Input() possibleValues: MultiCheckboxInputOption[] = [];

  @Input() selectedValues: string[];
  @Output() selectedValuesChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit(): void {}

  public onValueSelectedChange(value: string, isSelected: boolean) {
    if (isSelected) {
      const newSelectedValues = [...this.selectedValues, value];
      this.selectedValuesChange.emit(newSelectedValues);
    } else {
      const newSelectedValues = this.selectedValues.filter((val) => val !== value);
      this.selectedValuesChange.emit(newSelectedValues);
    }
  }
}
