import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { OAuthModule, OAuthModuleConfig, OAuthStorage } from "angular-oauth2-oidc";
import { SuperAdminGuard } from "./super-admin-guard.service";
import { authAppInitializerFactory } from "./auth-app-initializer.factory";
import { AuthGuard } from "./auth-guard.service";
import { authModuleConfig } from "./auth-module-config";
import { AuthService } from "./auth.service";
import { NoAuthGuard } from "./no-auth-guard.service";
import { CompanyAdminGuard } from "./company-admin-guard.service";

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [HttpClientModule, OAuthModule.forRoot()],
  providers: [AuthService, AuthGuard, NoAuthGuard, SuperAdminGuard, CompanyAdminGuard],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: authAppInitializerFactory,
          deps: [AuthService],
          multi: true,
        },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the AppModule only");
    }
  }
}
