import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { SupportService } from "src/app/services/support.service";
import { environment } from "src/environments/environment";

interface LanguagePickerLanguage {
  name: string;
  link: string;
}
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-language-picker",
  templateUrl: "./language-picker.component.html",
  styleUrls: ["./language-picker.component.css"],
})
export class LanguagePickerComponent implements OnInit {
  languages: LanguagePickerLanguage[] = [];
  selectedLanguage = location.origin;

  window = window;
  location = location;

  constructor(private auth: AuthService, private support: SupportService) {}

  ngOnInit(): void {
    this.languages = environment.languages;
  }

  navigateToLanguage(lang: any) {
    const selectedUrl = lang.target.value;
    const companyName: string | undefined = this.auth.loggedInUser?.company?.name;
    const newUrl = this.support.addQueryParamsToUrl(location.href, { company: companyName });
    window.open(selectedUrl + newUrl, "_self");
  }
}
