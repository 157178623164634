import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authConfig: AuthConfig = {
  clientId: "web",
  responseType: "code",
  redirectUri: window.location.origin + "/",
  silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",
  scope: "openid profile email",
  useSilentRefresh: true,
  sessionChecksEnabled: true,
  showDebugInformation: false,
  clearHashAfterLogin: false,
  nonceStateSeparator: "semicolon",
};

export const authServerUrl = environment.authServerUrl;

export const getAuthConfig = (companyName: string): AuthConfig => {
  return {
    issuer: `${authServerUrl}/realms/${companyName}`,
    ...authConfig,
  };
};
