import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MessagesService } from "./messages.service";

@Injectable({
  providedIn: "root",
})
export class SupportService {
  constructor(private toast: ToastrService, private msgT: MessagesService) {}

  public checkPasswordStrength(password: string) {
    let count = 0;

    if (password.length >= 10) {
      const regexpNumber: RegExp = /.*\d.*/;
      if (regexpNumber.test(password)) {
        count++;
      }

      const regexpSmall: RegExp = /.*[a-z].*/;
      if (regexpSmall.test(password)) {
        count++;
      }

      const regexpCapital: RegExp = /.*[A-Z].*/;
      if (regexpCapital.test(password)) {
        count++;
      }

      const regexpSpecial: RegExp = /.*[*.!@#$%^&(){}[]:";'<>,.?\/~`_+-=|\\].*/;
      if (regexpSpecial.test(password)) {
        count++;
      }
    }

    const isStrongEnough = count >= 3;
    if (!isStrongEnough) {
      this.toast.error(this.msgT.passwordTooWeak());
    }

    return isStrongEnough;
  }

  public validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  public addQueryParamsToUrl(url: string, queryParams?: Record<string, any>): string {
    const urlObj = new URL(url);

    if (queryParams == null) {
      return urlObj.pathname;
    }

    for (const [key, value] of Object.entries(queryParams)) {
      urlObj.searchParams.set(key, value);
    }

    return urlObj.pathname + urlObj.search;
  }

  public removeQueryParamsFromUrl(url: string, queryParams: string[]) {
    const urlObj = new URL(url);

    for (const queryParam of queryParams) {
      urlObj.searchParams.delete(queryParam);
    }

    return urlObj.pathname + urlObj.search;
  }
}
