import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/core/auth.service";
import { MessagesService } from "src/app/services/messages.service";
import { RestService } from "src/app/services/rest.service";
import { SupportService } from "src/app/services/support.service";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.css"],
})
export class UserProfileComponent implements OnInit {
  email: string;
  firstName: string;
  lastName: string;
  roles: string;

  newPassword: string = "";
  confirmNewPassword: string = "";

  loading = false;

  constructor(
    public authService: AuthService,
    private support: SupportService,
    private http: RestService,
    public msg: MessagesService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    this.authService.hasLoggedInUserLoaded$.subscribe((isDone) => {
      if (isDone) {
        this.email = this.authService.loggedInUser.email;
        this.firstName = this.authService.loggedInUser.firstName;
        this.lastName = this.authService.loggedInUser.lastName;
        this.roles = this.authService.loggedInUser.roles.map((role) => this.msg.translateRole(role)).join(", ");
      }
    });
  }

  async updateProfile() {
    this.loading = true;

    try {
      await this.http.postRequest(`api/authentication/updateProfile`, {
        firstName: this.firstName,
        lastName: this.lastName,
      });
      this.toast.success(this.msg.updateSuccess());
    } catch (e) {
      this.toast.error(this.msg.unknownError());
      console.error(e);
    }

    this.loading = false;
  }

  async changePassword() {
    if (!this.support.checkPasswordStrength(this.newPassword)) {
      return;
    }

    this.loading = true;

    if (this.newPassword.length < 2) {
      this.toast.error(this.msg.passwordTooWeak());
      this.loading = false;
      return;
    }

    if (this.newPassword !== this.confirmNewPassword) {
      this.toast.error(this.msg.passwordDontMatch());
      this.loading = false;
      return;
    }

    try {
      await this.http.postRequest(`api/authentication/changePassword`, {
        password: this.newPassword,
      });
      this.newPassword = "";
      this.confirmNewPassword = "";
      this.toast.success(this.msg.updateSuccess());
    } catch (e) {
      this.toast.error(this.msg.unknownError());
      console.error(e);
    }

    this.loading = false;
  }
}
