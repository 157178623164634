<div class="d-flex flex-wrap" style="gap: 8px">
  <app-checkbox
    *ngFor="let value of possibleValues"
    [value]="selectedValues.includes(value.value)"
    (valueChange)="onValueSelectedChange(value.value, $event)"
    name="{{ value.value }}"
  >
    <ng-container i18n>{{ value.name }}</ng-container>
  </app-checkbox>
</div>
