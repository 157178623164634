<div class="container app-container" *ngIf="authService.loggedInUser">
  <h1 class="app-heading">
    <ng-container i18n>Profile settings</ng-container>
  </h1>

  <app-accordion-with-content i18n-label label="Basic profile information">
    <div class="form-group">
      <label for="email"><ng-container i18n>Username</ng-container></label>
      <input type="text" class="form-control" id="email" disabled [(ngModel)]="email" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>First name</ng-container></label>
      <input type="text" class="form-control" id="name" [(ngModel)]="firstName" (keydown.enter)="updateProfile()" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>Last name</ng-container></label>
      <input type="text" class="form-control" id="phoneNumber" [(ngModel)]="lastName" (keydown.enter)="updateProfile()" />
    </div>
    <div class="form-group">
      <label for="name"><ng-container i18n>Roles</ng-container></label>
      <input type="text" class="form-control" id="roles" disabled [(ngModel)]="roles" />
    </div>

    <button type="button" class="btn btn-primary" i18n [disabled]="loading" (click)="updateProfile()">Update</button>
  </app-accordion-with-content>

  <br />

  <app-accordion-with-content i18n-label label="Change password">
    <div class="form-group">
      <label for="newPassword"><ng-container i18n>New password</ng-container></label>
      <input type="password" class="form-control" id="newPassword" [(ngModel)]="newPassword" (keydown.enter)="changePassword()" />
    </div>
    <div class="form-group">
      <label for="confirmNewPassword"><ng-container i18n>Confirm new password</ng-container></label>
      <input type="password" class="form-control" id="confirmNewPassword" [(ngModel)]="confirmNewPassword" (keydown.enter)="changePassword()" />
    </div>

    <button type="button" class="btn btn-primary" i18n [disabled]="loading" (click)="changePassword()">Change password</button>
  </app-accordion-with-content>
</div>
